<template>
  <v-container
    fluid
    id="dashboard"
    tag="section"
  >
    <h1
      class="text-center black--text"
      style="font-size: 3rem;"
      v-if="dispositivo"
    >
      {{ dispositivo.nombre }}
    </h1>
    <template
      v-for="(grafica, index) in graficasHistoricas"
    >
      <v-row
        :key="index+ '-codigo'"
        class="justify-space-around"
      >
        <v-col
          class="d-flex flex-column"
          cols="12"
          md="6"
          sm="10"
        >
          <v-card
            class="pa-3"
            color="#F7F8FC"
          >
            <apexchart
              :options="grafica.options"
              :series="grafica.series"
              ref="graficaHistorica"
            />
            <v-card-actions class="py-0 ma-0">
              <v-spacer/>
              <v-btn
                @click="grafica.actions = !grafica.actions"
                icon
              >
                <v-icon color="black">
                  {{ grafica.actions ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
              </v-btn>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="grafica.actions">
                <v-divider
                  class="mb-2"
                  style="padding-left: -3em"
                />
                <v-card-actions class="py-0 px-6">
                  <v-container
                    class="pa-0 ma-0"
                  >
                    <v-row class="mb-5 my-sm-0">
                      <v-col
                        class="d-flex text-right pa-0 flex-wrap-reverse flex-row-reverse justify-center justify-sm-start align-end"
                        sm="12"
                      >
                        <div
                          class="d-flex flex-grow-1 flex-sm-grow-0 flex-shrink-0 mx-2 my-0 actions__inputs"
                        >
                          <v-text-field
                            min="1"
                            single-line
                            solo
                            type="number"
                            v-model="grafica.periodo.numero"
                            background-color="#F7F8FC"
                            color="black"
                            class="actions__input"
                          />
                          <v-select
                            :items="tiempos"
                            single-line
                            solo
                            v-model="grafica.periodo.tiempo"
                            background-color="#F7F8FC"
                            color="black"
                            class="actions__input"
                          />
                        </div>
                        <span
                          class="text-caption grey--text font-weight-light mx-2 flex-shrink-1"
                          style="height: 48px; line-height: 48px; flex-basis: 10%"
                        >
                          Hace
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="mb-5 my-sm-0">
                      <v-col
                        class="d-flex text-right pa-0 flex-wrap-reverse flex-row-reverse justify-center justify-sm-start align-end"
                        sm="12"
                      >
                        <div
                          class="d-flex flex-grow-1 flex-sm-grow-0 flex-shrink-0 mx-2 my-0 actions__inputs"
                        >
                          <v-select
                            :items="sensores"
                            chips
                            deletable-chips
                            hide-selected
                            item-text="nombre"
                            item-value="codigo"
                            multiple
                            no-data-text="No hay mas sensores disponibles"
                            single-line
                            solo
                            v-model="grafica.sensoresSeleccionados"
                            background-color="#F7F8FC"
                            color="black"
                            class="actions__input"
                          />
                        </div>
                        <span
                          class="text-caption grey--text font-weight-light mx-2"
                          style="height: 48px; line-height: 48px"
                        >
                          Sensores
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="mb-5 my-sm-0">
                      <v-col
                        class="d-flex text-right pa-0 flex-wrap-reverse flex-row-reverse justify-center justify-sm-start align-end"
                        sm="12"
                      >
                        <div
                          class="d-flex flex-grow-1 flex-sm-grow-0 flex-shrink-0 mx-2 my-0 actions__inputs"
                        >
                          <v-select
                            :items="tipoGraficasHistoricas"
                            hide-selected
                            single-line
                            solo
                            v-model="grafica.tipo"
                            background-color="#F7F8FC"
                            color="black"
                            class="actions__input"
                          />
                        </div>
                        <span
                          class="text-caption grey--text font-weight-light mx-2"
                          style="height: 48px; line-height: 48px"
                        >
                          Sensores
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="d-flex justify-end pa-0"
                        cols="12"
                      >
                        <v-btn
                          @click="actualizarGrafica(index)"
                          class="mx-2"
                          height="48px"
                        >
                          Actualizar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-actions>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col
          class="d-flex flex-column"
          cols="12"
          md="6"
          sm="10"
        >
          <v-card
            class="pa-3"
            color="#F7F8FC"
          >
            <apexchart
              :options="graficasActuales[index].options"
              :series="graficasActuales[index].series"
              ref="graficaActual"
            />
          </v-card>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-btn
            class="mx-2"
            height="48px"
            @click="openExportDataModal(index)"
          >
            Exportar data histórica
          </v-btn>
        </v-col>
      </v-row>
      <hr
        v-if="index !== graficasHistoricas.length - 1"
        :key="index+ '-hr'"
      >
    </template>
    <export-data-form
      v-if="isExportDataModalOpen"
      v-model="isExportDataModalOpen"
      :sensor="sensorToExportData"
      @exported="isExportDataModalOpen = false"
      @error="handleExportError"
    />
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import moment from 'moment'
  import Paho from 'paho-mqtt/paho-mqtt'
  import ExportDataForm from '@/components/sensores/ExportDataForm'

  const es = require('apexcharts/dist/locales/es.json')
  export default {
    name: 'Dispositivo',
    components: {
      ExportDataForm,
    },
    data: () => {
      return {
        isExportDataModalOpen: false,
        sensores: [],
        dispositivo: null,
        sensorToExportData: null,
        graficasHistoricas: [],
        graficasActuales: [],
        tipoGraficasHistoricas: [
          {
            value: 'line',
            text: 'Linear',
          },
          {
            value: 'bar',
            text: 'Barras',
          },
          {
            value: 'area',
            text: 'Area',
          },
        ],
        tiempos: [
          {
            value: 'hours',
            text: 'Hora(s)',
          },
          {
            value: 'days',
            text: 'Día(s)',
          },
          {
            value: 'months',
            text: 'Mes(es)',
          },
          {
            value: 'years',
            text: 'Año(s)',
          },
        ],
      }
    },
    computed: {
      ...mapState('perfil', ['empresa']),
    },
    async mounted () {
      await this.fetchEmpresa()
      let sensoresResponse = null
      let dispositivoResponse = null
      try {
        sensoresResponse = await this.$http.get(`/iot/dispositivos/${this.$route.params.codigo}/sensores/`)
        dispositivoResponse = await this.$http.get(`/iot/dispositivos/${this.$route.params.codigo}/`)
      } catch (e) {
        this.$toast.error('Dispositivo no encontrado')
        this.$router.push({ name: 'Mis Dispositivos' })
        return
      }
      this.dispositivo = dispositivoResponse.data
      this.sensores = sensoresResponse.data
      this.sensores.forEach((sensor, index) => {
        const graficaHistorica = {}
        graficaHistorica.periodo = {
          numero: 1,
          tiempo: 'days',
        }
        graficaHistorica.tipo = 'area'
        graficaHistorica.actions = false
        graficaHistorica.sensoresSeleccionados = [sensor.codigo]
        graficaHistorica.options = {
          chart: {
            animations: {
              easing: 'linear',
              id: 'vuechart-historica' + sensor.codigo,
            },
            background: 'transparent',
            defaultLocale: 'es',
            dropShadow: {
              blur: 10,
              color: '#000',
              enabled: true,
              left: 7,
              opacity: 0.2,
              top: 18,
            },
            locales: [es],
            toolbar: {
              export: {
                csv: {
                  dateFormatter (timestamp) {
                    return moment(timestamp).add(5, 'h').format('DD/MM/YYYY HH:mm:ss')
                  },
                  filename: `${sensor.codigo}@${this.$route.params.codigo}@${this.empresa.codigo}`,
                  headerCategory: 'Fecha',
                  headerValue: 'Registro',
                },
              },
            },
            type: 'area', // bar, line, area,
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            horizontalAlign: 'center',
            position: 'top',
          },
          noData: {
            style: {
              color: 'black',
              fontSize: '2em',
            },
            text: 'No hay datos',
          },
          stroke: {
            curve: 'smooth',
          },
          subtitle: {
            style: {
              color: 'black',
            },
            text: sensor.descripcion,
          },
          title: {
            style: {
              color: 'black',
            },
            text: `${sensor.nombre}(${sensor.unidad_medida.simbolo})`,
          },
          tooltip: {
            style: {
              color: 'black',
            },
            theme: 'dark',
            x: {
              format: 'dd MMM H:mm:ss',
              show: true,
            },
          },
          xaxis: {
            label: {
              datetimeUTC: false,
            },
            type: 'datetime',
          },
        }

        const graficaActual = {}
        graficaActual.tipo = 'radialBar'
        graficaActual.actions = false
        graficaActual.sensorSeleccionado = sensor.codigo
        graficaActual.options = {
          chart: {
            animations: {
              easing: 'linear',
              id: 'vuechart-actual' + sensor.codigo,
            },
            background: 'transparent',
            defaultLocale: 'es',
            locales: [es],
            type: 'radialBar', // radialBar
            umed: [sensor.umed],
          },
          colors: ['#4CAF50'],
          fill: {
            colors: [({ value, seriesIndex, w }) => {
              const isUmbralAscendent = sensor.tipo_umbral === 'ascendente'
              const realValue = value * sensor.umbral_alto / 100
              if (isUmbralAscendent) {
                if (realValue < sensor.umbral_medio) {
                  return '#4CAF50'
                } else if (realValue >= sensor.umbral_medio && realValue < sensor.umbral_alto) {
                  return '#FFC107'
                } else {
                  return '#FF5252'
                }
              } else {
                if (realValue > sensor.umbral_medio) {
                  return '#4CAF50'
                } else if (realValue <= sensor.umbral_medio && realValue > sensor.umbral_alto) {
                  return '#FFC107'
                } else {
                  return '#FF5252'
                }
              }
            }],
          },
          labels: [sensor.nombre],
          legend: {
            horizontalAlign: 'center',
            position: 'top',
          },
          noData: {
            style: {
              color: 'black',
              fontSize: '2em',
            },
            text: 'No hay datos',
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: +90,
              dataLabels: {
                name: {
                  color: 'black',
                  fontSize: '0.75em',
                  fontWeight: 'normal',
                  offsetY: -15,
                },
                value: {
                  color: 'black',
                  fontSize: '1.5em',
                  fontWeight: 'bold',
                  offsetY: -10,
                  formatter: function (val) {
                    return `${(val / 100 * sensor.umbral_alto).toFixed(2)} ${sensor.unidad_medida.simbolo}`
                  },
                },
                total: {
                  show: false,
                  label: 'Mayor',
                  fontSize: '1.5em',
                  fontWeight: 'bold',
                  color: 'black',
                },
              },
            },
          },
          subtitle: {
            style: {
              color: 'black',
            },
            text: sensor.descripcion,
          },
          title: {
            style: {
              color: 'black',
            },
            text: `${sensor.nombre}(${sensor.unidad_medida.nombre})`,
          },
          toolbar: {
            show: true,
          },
          tooltip: {
            style: {
              color: 'black',
            },
            theme: 'dark',
          },
        }
        graficaActual.series = [10]
        this.graficasHistoricas.push(graficaHistorica)
        this.graficasActuales.push(graficaActual)
        // Se hizo sin await porque el forEach no acepta promesas,
        // además tiene una ventaja de que se esta promesa no bloquea al traer la data, en caso sea bastante
        this.fetchRegistros(this.$route.params.codigo, sensor.codigo).then(registrosResponse => {
          const registros = registrosResponse.data
          if (sensor.valor_actual) {
            const ultimoValorRegistro = sensor.valor_actual / sensor.umbral_alto * 100
            graficaActual.series = [ultimoValorRegistro]
          } else {
            graficaActual.series = []
          }
          graficaHistorica.series =
            [{
              name: sensor.nombre,
              codigo: sensor.codigo,
              data: registros,
            }]
          this.$refs.graficaHistorica[index].updateSeries(graficaHistorica.series)
          this.$refs.graficaActual[index].updateSeries(graficaActual.series)
        })
      })
      const client = new Paho.Client('mqtt.cobi.pe', 8083, '', '')
      client.onMessageArrived = (message) => {
        const [, , , sensor_codigo] = message.topic.split('/')
        const graficaActualIndex = this.graficasActuales.findIndex((graficaActual) => graficaActual.sensorSeleccionado === sensor_codigo)
        const medida = parseFloat(message.payloadString)
        const porcentaje = medida / this.sensores[graficaActualIndex].umbral_alto * 100
        this.graficasActuales[graficaActualIndex].series[0] = porcentaje
        this.$refs.graficaActual[graficaActualIndex].updateSeries(this.graficasActuales[graficaActualIndex].series)
        this.graficasHistoricas.forEach((graficaHistorica, index) => {
          const serieIndex = graficaHistorica.series.findIndex(serie => serie.codigo === sensor_codigo)
          if (serieIndex !== -1) {
            const nuevoRegistro = {
              x: moment().subtract(5, 'hours').toDate(),
              y: medida,
              porcentaje,
            }
            graficaHistorica.series[serieIndex].data = [nuevoRegistro, ...graficaHistorica.series[serieIndex].data]
            this.$refs.graficaHistorica[index].updateSeries(graficaHistorica.series)
          }
        })
      }
      // client.onConnectionLost = (responseObject) => {
      //   console.error('Connection lost')
      //   console.error(responseObject)
      // }
      // client.onConnected = (onConnectedResponse) => {
      //   console.debug('Connection Completed to the broker')
      //   console.debug({ onConnectedResponse })
      // }
      client.connect(
        {
          // userName: 'admin',
          // password: 'secret',
          useSSL: true,
          // onFailure: (error) => {
          //   console.error('Can\'t connect to mqtt')
          //   console.error({ error })
          // },
          onSuccess: () => {
            // console.debug('Connection successes')
            const topic = `realtime/${this.empresa.codigo}/${this.$route.params.codigo}/+`
            client.subscribe(topic, {
              // onSuccess: () => {
              //   console.debug('Connected to topic: ' + topic)
              // },
            })
          },
        },
      )
    },
    methods: {
      ...mapActions('perfil', ['fetchEmpresa']),
      handleExportError () {
        alert('Hubo un error al exportar la data, consulte con soporte')
        this.isExportDataModalOpen = false
      },
      async actualizarGrafica (index) {
        this.actualizarTipoGrafica(index)
        await this.actualizarSensores(index)
      },
      actualizarTipoGrafica (index) {
        this.graficasHistoricas[index].options.chart.type = this.graficasHistoricas[index].tipo
        this.$refs.graficaHistorica[index].updateOptions(this.graficasHistoricas[index].options)
      },
      async actualizarSensores (index) {
        const series = []
        const titulo_sensores = []
        const codigosSensoresSeleccionados = this.graficasHistoricas[index].sensoresSeleccionados
        const numero = this.graficasHistoricas[index].periodo.numero
        const tiempo = this.graficasHistoricas[index].periodo.tiempo
        const timestamp_inicio = moment().subtract(numero, tiempo).valueOf()
        for (const codigo of codigosSensoresSeleccionados) { // eslint-disable-line
          const sensor = this.sensores.find((sensor) => sensor.codigo === codigo)
          const registrosResponse = await this.fetchRegistros(this.$route.params.codigo, sensor.codigo, timestamp_inicio)
          const nueva_serie = {
            name: sensor.nombre,
            data: registrosResponse.data,
          }
          series.push(nueva_serie)
          titulo_sensores.push(`${sensor.nombre}(${sensor.unidad_medida.simbolo})`)
        }
        this.graficasHistoricas[index].series = series
        this.graficasHistoricas[index].options.title.text = titulo_sensores.join(', ')
        this.$refs.graficaHistorica[index].updateSeries(series)
        this.$refs.graficaHistorica[index].updateOptions(this.graficasHistoricas[index].options)
      },
      fetchRegistros (codigo_dispositivo, codigo_sensor, timestamp_inicio = null) {
        if (timestamp_inicio === null) {
          const fecha = moment().subtract(1, 'd').format('YYYY-MM-DD+HH:mm:ss')
          return this.$http.get(`/iot/registros/for_apex/?sensor__codigo=${codigo_sensor}&sensor__dispositivo__codigo=${codigo_dispositivo}&fecha_real__gte=${fecha}`)
        } else {
          moment(timestamp_inicio)
          const fecha = moment(timestamp_inicio).format('YYYY-MM-DD+HH:mm:ss')
          return this.$http.get(`/iot/registros/for_apex/?sensor__codigo=${codigo_sensor}&sensor__dispositivo__codigo=${codigo_dispositivo}&fecha_real__gte=${fecha}`)
        }
      },
      openExportDataModal (sensorIndex) {
        this.sensorToExportData = this.sensores[sensorIndex] || null
        this.isExportDataModalOpen = true
      },
    },
  }
</script>
<style lang="scss" scoped>
#dashboard {
  background-color: #F7F8FC !important;
}

  .apexcharts-menu.apexcharts-menu-open {
    background: #54545454;
  }

  .apexcharts-theme-light .apexcharts-menu-item:hover {
    color: black;
  }

  .actions {
    &__inputs {
      flex-basis: 50%;
    }
    &__input::v-deep input {
      color: black !important;
    }
    &__input::v-deep .v-select__selection {
      color: black !important;
    }
  }
</style>
