<template>
  <v-dialog
    v-on="$listeners"
    v-bind="$attrs"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Exportar Data del sensor {{ sensor.nombre || '' }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-menu
                  v-model="ismenuStartDateActive"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedStartDate"
                      label="Fecha de inicio"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      color="white"
                    />
                  </template>
                  <v-date-picker
                    v-model="form.startDate"
                    @input="ismenuStartDateActive = false"
                    locale="es"
                    :max="today"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
              >
                <v-menu
                  v-model="ismenuEndDateActive"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedEndDate"
                      label="Fecha de Fin"
                      prepend-icon="mdi-calendar"
                      :rules="[isDateGreathenThanStartDate]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      color="white"
                    />
                  </template>
                  <v-date-picker
                    v-model="form.endDate"
                    @input="ismenuEndDateActive = false"
                    locale="es"
                    :max="today"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="primary"
          class="mb-3"
          @click="exportData"
          :loading="loading"
        >
          Descargar
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// import { mapMutations, mapState } from 'vuex'

  import dayjs from 'dayjs'
  import axios from 'axios'

  export default {
    name: 'ExportDataForm',
    inheritAttrs: false,
    props: {
      sensor: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        today: dayjs().format('YYYY-MM-DD'),
        form: {
          startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        },
        ismenuStartDateActive: false,
        ismenuEndDateActive: false,
        loading: false,
      }
    },
    computed: {
      formattedEndDate () {
        const endDate = dayjs(this.form.endDate, 'YYYY-MM-DD')
        return endDate.isValid() ? endDate.format('DD/MM/YYYY') : null
      },
      formattedStartDate () {
        const startDate = dayjs(this.form.startDate, 'YYYY-MM-DD')
        return startDate.isValid() ? startDate.format('DD/MM/YYYY') : null
      },
    },
    methods: {
      isDateGreathenThanStartDate (value) {
        const startDate = dayjs(this.form.startDate, 'YYYY-MM-DD')
        const endDate = dayjs(this.form.endDate, 'YYYY-MM-DD')
        return endDate.isSameOrAfter(startDate) || 'La fecha de fin no puede ser menor a la fecha de inicio'
      },
      async exportData () {
        if (!this.$refs.form.validate()) {
          return
        }
        this.loading = true
        try {
          const dataResponse = await axios.post(`iot/sensores/${this.sensor.id}/exportar_datos_historicos/`, {
            fecha_inicio: this.form.startDate,
            fecha_fin: this.form.endDate,
          }, { responseType: 'blob' })
          const fileFormattedEndDate = this.formattedEndDate.replaceAll('/', '-')
          const fileFormattedStartDate = this.formattedStartDate.replaceAll('/', '-')
          const url = window.URL.createObjectURL(new Blob([dataResponse.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.sensor.nombre}_${fileFormattedStartDate}_${fileFormattedEndDate}.csv`)
          document.body.appendChild(link)
          link.click()
          this.$emit('exported')
        } catch (e) {
          this.$emit('error', e)
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>

<style scoped lang="scss">
.completed {
  &__icon {
    margin: 50px 0 23px;
  }

  &__title {
    font-weight: 700;
    font-size: 35px;
    line-height: 52px;
    text-align: center;

    color: #3626A7;
    margin-bottom: 15px;
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #2D2D2A;
    margin: 0 152px 80px;
    //margin-bottom: 122px;
  }

  &__button {
    margin-bottom: 50px;
  }
}

.lead-popup {
  &__wrapper {
    background: #FFFFFF !important;
    border-radius: 30px;
    padding: 0 116px;
    max-height: 100%;
    min-height: 100%;

  }

  &__title {
    font-weight: 700;
    font-size: 35px;
    line-height: 52px;
    text-align: center;
    color: #3626A7;
    margin: 15px 0 10px;
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #2D2D2A;
    margin-bottom: 20px;
  }
}

.form {
  &__wrapper {
    width: 446px;
    margin-bottom: 15px;

    .container::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 4px;
    }

  }

  &__input {
    max-width: 100%;
    margin-bottom: 25px;

    &:last-of-type {
      margin-bottom: 35px;
    }
  }

  &__button[type=submit] {
    margin-bottom: 0;
    height: 63px;
    min-height: 63px;
  }
}

@media screen and (max-width: 600px) {
  .completed {
    &__title {
      font-size: 18px;
      line-height: 27px;
    }

    &__text {
      font-size: 14px;
      line-height: 21px;
      margin: 0 35px 50px;
    }
  }
  .lead-popup {
    &__wrapper {
      padding: 0 35px;
    }

    &__title {
      font-size: 18px;
      line-height: 27px;
    }

    &__text {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.form {
  &__wrapper {
    width: 100%;
  }

  &__title {
    margin: 10px 0 20px;
  }

  &__input {
    //max-width: 100%;

    &:last-of-type {
      margin-bottom: 25px;

    }
  }

}

</style>
